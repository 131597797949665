<app-header></app-header>

<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 offset-lg-4 offset-md-3">
        <div class="alert alert-dismissible fade {{notification['class']}} mb-2" role="alert" *ngIf="notification">
          {{notification['message']}}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <form class="g-3" [formGroup]="fpForm" (ngSubmit)="onSubmit()">
          <div class="card">
            <div class="card-header text-center">
              Reset Password
            </div>
            <div class="card-body">
              <div class="mb-2">
                <input type="text" formControlName="mobile_no" class="form-control" placeholder="Mobile No./Email" [ngClass]="{'is-invalid': (isSubmitted || f.mobile_no.touched) && f.mobile_no.invalid}" />
                <span class="invalid-feedback" *ngIf="f.mobile_no.hasError('required')">Please Enter Mobile No.</span>
                <span class="invalid-feedback" *ngIf="f.mobile_no.hasError('pattern') || f.mobile_no.hasError('maxlength') || f.mobile_no.hasError('minlength')">Please Enter Valid Mobile No.</span>
              </div>
              <div class="mb-2">
                <input type="password" formControlName="new_password" class="form-control" placeholder="New Password" [ngClass]="{'is-invalid': (isSubmitted || f.new_password.touched) && f.new_password.invalid}" />
                <span class="invalid-feedback" *ngIf="f.new_password.hasError('required')">Please Enter New Password</span>
                <span class="invalid-feedback" *ngIf="f.new_password.hasError('maxlength') || f.new_password.hasError('minlength')">Password must be between 6 to 18 characters</span>
              </div>
              <div class="mb-2">
                <input type="password" formControlName="confirm_new_password" class="form-control" placeholder="Confirm Password" [ngClass]="{'is-invalid': (isSubmitted || f.confirm_new_password.touched) && f.confirm_new_password.invalid}" />
                <span class="invalid-feedback" *ngIf="f.confirm_new_password.hasError('required')">Please Enter Confirm Password</span>
                <span class="invalid-feedback" *ngIf="f.confirm_new_password.hasError('matching')">Confirm password not match</span>
              </div>
              <div>
                <input type="number" *ngIf="viewOtp" formControlName="otp" class="form-control" placeholder="OTP" [ngClass]="{'is-invalid': (isSubmitted || f.otp.touched) && f.otp.invalid}" />
                <span class="invalid-feedback" *ngIf="f.otp.hasError('required')">Please Enter OTP</span>
              </div>
            </div>
            <div class="card-footer text-muted">
              <div class="row align-items-center">
                <div class="col-6 text-start"></div>
                <div class="col-6 text-end">
                  <button type="submit" class="btn btn-auto btn-sm" *ngIf="viewOtp">Reset Password</button>
                  <button type="button" class="btn btn-auto btn-sm" (click)="sendOtp()" *ngIf="!viewOtp">Send OTP</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<app-screen-loader [isShow]="isLoadershow"></app-screen-loader>
<app-footer></app-footer>
