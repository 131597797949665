<app-header></app-header>
<!--Breadcrumbs-->
<app-breadcrumbs [mainTitle]="mainTitle" [pageTitle]="pageTitle"></app-breadcrumbs>
<!--End-Breadcrumbs-->
<!--Section-->
<section>
  <div class="container py-5">
    <div class="row">
      <div class="col-sm-8">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3547.7357161736513!2d78.23593321452756!3d27.227446153025454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3974688f8eeef0b5%3A0x84ae62212bb44afa!2sChrist%20The%20King%20Inter%20College!5e0!3m2!1sen!2sin!4v1592993951292!5m2!1sen!2sin"
          width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
          tabindex="0"></iframe>
      </div>
      <div class="col-sm-4">
        <app-enquiry-form></app-enquiry-form>
      </div>
    </div>
    <div class="row mt-5" *ngFor="let school of schoolInfo">
      <div class="col-sm-4">
        <div class="contact-info">
          <div class="contact-icon">
            <i class="fa fa-map"></i>
          </div>
          <div class="contant-details">
            <h3>Location</h3>
            <p class="m-0">{{school.address}} {{school.postal_code}}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="contact-info">
          <div class="contact-icon">
            <i class="fa fa-envelope"></i>
          </div>
          <div class="contant-details">
            <h3>Email Address</h3>
            {{school.email}}
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="contact-info">
          <div class="contact-icon">
            <i class="fa fa-phone"></i>
          </div>
          <div class="contant-details">
            <h3>Get in Touch</h3>
            <p class="m-0">{{school.phone_num1}}, {{school.phone_num2}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
