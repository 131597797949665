<div class="invoice-wrapper p-3" style="width: 400px;">
  <div class="invoice-header d-flex justify-content-between align-items-center">
    <div class="logo"><img src="assets/images/logo.png" alt="" width="170px"></div>
    <div><strong>Order No. : </strong>{{data['order_id']}}</div>
  </div>

  <div class="p-2">
    <table class="table">
      <tbody>
        <tr>
          <td>Name</td>
          <td class="text-end">abc</td>
        </tr>
        <tr>
          <td>Email</td>
          <td class="text-end">abc</td>
        </tr>
        <tr>
          <td>Mobile No.</td>
          <td class="text-end">abc</td>
        </tr>
        <tr>
          <td>Plan</td>
          <td class="text-end">abc</td>
        </tr>
        <tr>
          <td>Duration</td>
          <td class="text-end">abc</td>
        </tr>
        <tr>
          <td>Transaction Date</td>
          <td class="text-end">abc</td>
        </tr>
        <tr class="fw-bold">
          <td>Total Amount</td>
          <td class="text-end">abc</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mt-5 text-center fw-bold">
    (it is a computer generated copy. it's not required signature.)
  </div>
</div>
