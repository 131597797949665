<app-dash-header></app-dash-header>

<nav class="breadcrumb-wrapper" id="breadcrumbWrapper" aria-label="breadcrumb">
  <ol class="breadcrumb m-0 animate__animated animate__slideInDown">
    <li class="breadcrumb-item">Purchase Membership</li>
  </ol>
</nav>

<section class="section">
  <div class="fw-bold fs-5 text-center my-5 text-danger">If you want to access special options, Please Purchase Membership !</div>
  <div class="row">
    <div class="col-12 mb-3" *ngIf="!isNRI && membershipLen">
      <div class="row">
        <div class="col-sm-6 mb-3" *ngFor="let membership of memberships">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-3 fw-bold">{{membership.subscription_name}} Membership</h4>
              <p class="card-text mb-0"><strong>Amount : </strong>{{membership.subscription_fee}}/-</p>
              <p class="card-text" *ngIf="membership.subscription_duration != 'lifetime'"><strong>Duration : </strong>{{membership.subscription_duration}} Days</p>
              <p class="card-text" *ngIf="membership.subscription_duration == 'lifetime'"><strong>Duration : </strong>{{membership.subscription_duration}}</p>
              <!-- <a href="{{membership.payment_link}}" target="_blank" class="btn btn-primary">Pay</a> -->
              <button type="button" class="btn btn-primary" (click)="purchaseMembership(membership.id)">Pay</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3" *ngIf="isNRI && membershipLen">
      <div class="card">
        <div class="card-body">
          <div><span class="fw-bold">Bank A/c no. : </span>11111111111111111111</div>
          <div><span class="fw-bold">IFSC Code : </span>BNK01234</div>
          <div><span class="fw-bold">Branch : </span>Demo</div>
          <h4 class="fw-bold m-0 mt-2">Plans : </h4>
          <div class="row">
            <div class="col-sm-6 mt-1 mb-1" *ngFor="let membership of memberships;">
              <div class="card">
                <div class="card-body">
                  <h6 class="card-title m-0 mt-1 fw-bold">{{membership.subscription_name}} Membership</h6>
                  <p class="card-text mb-0"><strong>Amount : </strong>{{membership.subscription_fee}}/-</p>
                  <p class="card-text" *ngIf="membership.subscription_duration != 'lifetime'"><strong>Duration : </strong>{{membership.subscription_duration}} Days</p>
                  <p class="card-text" *ngIf="membership.subscription_duration == 'lifetime'"><strong>Duration : </strong>{{membership.subscription_duration}}</p>
                </div>
              </div>
            </div>
          </div>
          <div><span class="fw-bold">Note : </span> After Complete your transaction. Please send transaction details or screenshot to us at <span class="fw-bold">+91-9045506958.</span></div>
        </div>
      </div>
    </div>
  </div>
</section>
