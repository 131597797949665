<app-header></app-header>
<!--Breadcrumbs-->
<app-breadcrumbs [mainTitle]="mainTitle" [pageTitle]="pageTitle"></app-breadcrumbs>
<!--End-Breadcrumbs-->
<!--Section-->
<section class="py-5">
  <div class="container">
    <!-- <ul class="tree">
      <li class="">
        <a href="javascript:void(0)">
          KNOW US
        </a>
        <ul class="">
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/know-us">KNOW US</a></li>
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/aims-and-objectives">AIMS & OBJECTIVES</a></li>
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/membership">MEMBERSHIP</a></li>
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/board-of-member">BOARD OF MEMBERS</a></li>
        </ul>
      </li>
      <li class="">
        <a href="javascript:void(0)">
          ABOUT SCHOOL
        </a>
        <ul class="">
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/about-school">ABOUT SCHOOL</a></li>
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/former-principal">FORMER PRINCIPALS</a></li>
        </ul>
      </li>
      <li class="">
        <a href="javascript:void(0)">
          GALLERY
        </a>
        <ul class="">
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/gallery">PHOTO/VIDEO</a></li>
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/media-gallery">MEDIA GALLERY</a></li>
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/class-photo">CLASS PHOTO</a></li>
        </ul>
      </li>
      <li class="">
        <a class="" routerLink="/faq">FAQs</a>
      </li>
      <li class="">
        <a class="" routerLink="/wall-of-fame">WALL OF FAME</a>
      </li>
      <li class="">
        <a href="javascript:void(0)">
          CONTACT US
        </a>
        <ul class="">
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/contact-us">CONTACT US</a></li>
          <li><div class="bar">&nbsp;</div><a class="" routerLink="/site-map">SITE MAP</a></li>
        </ul>
      </li>
    </ul> -->
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-4">
        <ul class="tree">
          <li class="">
            <a href="javascript:void(0)">
              KNOW US
            </a>
            <ul class="">
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/know-us">KNOW US</a></li>
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/aims-and-objectives">AIMS & OBJECTIVES</a></li>
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/membership">MEMBERSHIP</a></li>
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/board-of-member">BOARD OF MEMBERS</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-4">
        <ul class="tree">
          <li class="">
            <a href="javascript:void(0)">
              ABOUT SCHOOL
            </a>
            <ul class="">
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/about-school">ABOUT SCHOOL</a></li>
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/former-principal">FORMER PRINCIPALS</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-4">
        <ul class="tree">
          <li class="">
            <a href="javascript:void(0)">
              GALLERY
            </a>
            <ul class="">
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/gallery">PHOTO/VIDEO</a></li>
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/media-gallery">MEDIA GALLERY</a></li>
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/class-photo">CLASS PHOTO</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-4">
        <ul class="tree">
          <li class="text-start text-sm-center">
            <a class="" routerLink="/faq">FAQs</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-4">
        <ul class="tree">
          <li class="text-start text-sm-center">
            <a class="" routerLink="/wall-of-fame">WALL OF FAME</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-4">
        <ul class="tree">
          <li class="">
            <a href="javascript:void(0)">
              CONTACT US
            </a>
            <ul class="">
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/contact-us">CONTACT US</a></li>
              <li><div class="bar">&nbsp;</div><a class="" routerLink="/site-map">SITE MAP</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
