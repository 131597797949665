<app-dash-header></app-dash-header>

<nav class="breadcrumb-wrapper" id="breadcrumbWrapper" aria-label="breadcrumb">
  <ol class="breadcrumb m-0 animate__animated animate__slideInDown">
    <li class="breadcrumb-item">Wall of Fame</li>
    <!-- <li class="breadcrumb-item active" aria-current="page">dfsdf</li> -->
  </ol>
</nav>
<section class="section">
  <div class="shadow rounded p-2 mb-3 bg-light" *ngFor="let fame of fames">
    <div class="row">
      <div class="col-4">
        <div class="img-wrapper" style="background-image: url('{{fame.fame_image}}'); height: 200px;">
          <div class="inner-wrapper"></div>
          <img src="{{fame.fame_image}}" />
        </div>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-8">
            <span class="fs-5 fw-bold">{{fame.fame_title}}</span>
          </div>
          <div class="col-4 text-end">
            <span>{{fame.fame_date | date: 'mediumDate'}}</span>
          </div>
        </div>
        <p>{{fame.fame_description}}</p>
      </div>
    </div>
  </div>
</section>
