<div class="breadcrumbs" style="background-image: url('assets/images/banner/banner_2.jpg');">
  <div class="breadcrumbs-wrapper">
      <ul class="breadcrumbs-nav">
          <li><a routerLink="/">{{mainTitle}}</a></li>
          <li>{{pageTitle}}</li>
      </ul>
      <div class="breadcrumbs-title-wrapper">
          <h2 class="breadcrumbs-title">{{pageTitle}}</h2>
      </div>
  </div>
</div>
