<app-dash-header></app-dash-header>

<nav class="breadcrumb-wrapper" id="breadcrumbWrapper" aria-label="breadcrumb">
  <ol class="breadcrumb m-0 animate__animated animate__slideInDown">
    <li class="breadcrumb-item">Events</li>
    <!-- <li class="breadcrumb-item active" aria-current="page">dfsdf</li> -->
  </ol>
</nav>
<section class="section">
  <div class="row">
    <div class="col-sm-6 mb-3" *ngFor="let event of events">
      <div class="bg-white news-event-box shadow p-2 rounded">
        <div class="news-event-full-date">
          <div class="news-event-day">{{event.from_date | date : 'EEE'}}</div>
          <div class="news-event-date">{{event.from_date | date : 'dd'}}</div>
          <div class="news-event-month">{{event.from_date | date : 'MMM'}}</div>
        </div>
        <div class="news-event-details">
          <h3 class="news-event-title">{{event.title}}</h3>
          <p class="news-event-description">{{event.description}}</p>
        </div>
      </div>
    </div>
  </div>
</section>
