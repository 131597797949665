<app-header></app-header>
<!--Breadcrumbs-->
<app-breadcrumbs [mainTitle]="mainTitle" [pageTitle]="pageTitle"></app-breadcrumbs>
<!--End-Breadcrumbs-->
<!--Section-->
<section>
  <div class="container py-5">
      <div class="img-wrapper float-start me-3 mb-2" style="background-image: url('assets/images/about_us.jpg'); width:350px;">
          <div class="inner-wrapper"></div>
          <img src="assets/images/about_us.jpg"/>
      </div>
      <p>Christ the King Inter College, Tundla, managed and administered by the Roman Catholic Diocese of Agra Education Society, is a minority Christian Institution. It was begun on 3rd July 1972 by late the Most Rev. Dr. Dominic Athaide (OFM Cap) then Archbishop of Agra. The school was conducted in rented buildings till June 1978. The present premises was inaugurated in July 1978. In July 1985, the school was affiliated to the Council for the Indian School Certificate Examinations, New Delhi and the first batch of the ICSE came out in flying colours in May 1987.</p>
      <p>The Most Rev. Dr. Albert D Souza procured a new plot of land in September 2007 to relocate the Primary section, On 15th August 2008 the first phase of Christ the King Primary school was commenced. On 3rd July 2009, the KG. section and in July 2010 classes I to lll were moved into the new building The entire primary section from classes L.K.G. to Vth began to function in the new campus from April 2012. Since the school became ready for upgradation, in July 2010, the dream of Plus Two studies came true with the commencement of ISC batch 2012 in Science & Commerce stream.</p>
      <p>Education is the process by which society deliberately transmits its accumulated knowledge, skills and values from one generation to another. We not only maintain the continuity but also bring out betterment and improvement in our culture and civilization. A dynamic educational process is instrumental to social change, infusing life into the systems that govern and act as the basis for the functioning of the society. Education gives new direction, opens up ever new vistas of outlooks, perspectives, and ideologies and prepares individuals to embark upon expeditions on the unknown terrains of thoughts and actions. Our faith in the efficacy of education is the only tool for social reforms; only answer and solution to the myriads of issues that afflict us; our determination to empower the weaker sections of the society through education. It leads to the establishment of a social order, based on equality of opportunity to progress and prosper without being exploited or deprived of inalienable human rights. The education gives great emphasis on the need to inculcate in students a great love for moral values, concern for the neighbour, refinement in behaviour and love for our culture.</p>
  </div>
</section>
<!--End-Section-->
<app-footer></app-footer>
