<app-header></app-header>
<!--Breadcrumbs-->
<app-breadcrumbs [mainTitle]="mainTitle" [pageTitle]="pageTitle"></app-breadcrumbs>
<!--End-Breadcrumbs-->
<!--Section-->
<section>
  <div class="container py-5">
    <div class="accordion" id="accordionFAQ">
      <div class="accordion-item" *ngFor="let faq of faqs;let i = index">
        <h2 class="accordion-header" [id]="'heading_'+i">
          <button class="accordion-button" [ngClass]="{ 'collapsed': i != 0 }" type="button" data-bs-toggle="collapse"[attr.data-bs-target]="'#collapse'+i" aria-expanded="true" [attr.aria-controls]="'collapse'+i">
           {{faq.faq_question}}
          </button>
        </h2>
        <div [id]="'collapse'+i" class="accordion-collapse collapse" [ngClass]="{ 'show': i == 0 }" [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionFAQ">
          <div class="accordion-body">
            <strong>Answer :</strong>{{faq.faq_answer}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
