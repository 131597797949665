<!-- <div *ngIf="checkAuth; else failed">
  <router-outlet></router-outlet>
</div>
<ng-template #failed>
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</ng-template> -->
<!-- <app-header *ngIf="!checkAuth"></app-header> -->
<!-- <router-outlet></router-outlet> -->
<!-- <app-footer *ngIf="!checkAuth"></app-footer> -->
<router-outlet></router-outlet>
