<app-header></app-header>
<!--Breadcrumbs-->
<app-breadcrumbs [mainTitle]="mainTitle" [pageTitle]="pageTitle" ></app-breadcrumbs>
<!--End-Breadcrumbs-->
<!--Section-->
<section>
  <div class="container py-5">
      <div class="img-wrapper float-start me-3 mb-2" style="background-image: url('assets/images/about_us.jpg'); width:350px;">
          <div class="inner-wrapper"></div>
          <img src="assets/images/about_us.jpg"/>
      </div>
      <p>Christ the King family is one of its kind. We are not just about studying and passing out exams. We are the life long support of our alums. Taking this view into consideration we have come up with the 'Christ The King Alumni Association' as a forum for interaction for all alums.</p>
      <p>Christ the King Alumni Association is a nodal forum for its alumni to initiate workmanship and brotherhood among the alums and to provide constructive direction to take the torch of the college in the field of life.</p>
      <p>Christ the King Alumni Association most importantly aims to improve the quality of life in our city. We help to foster awareness among people about gender equality, importance of education, career guidance, empowering socially deprived people, enhancing healthcare in rural areas, etc.. We have come forward with this platform to help the ones who are in need.</p>
      <p>Our Aims and Objectives :</p>
      <ul>
        <li>Initiating healthy environment among people sharing alma matter.</li>
        <li>Providing a common platform to interact and build positive dialogues.</li>
        <li>Working for the betterment of the college and its associates.</li>
        <li>Impacting the society by bringing up help whenever needed.</li>
        <li>Maintaining a balance between the college and society.</li>
      </ul>
      <p>Working in our association gives you a perspective on things and life in general, that ordinary people can’t normally see.</p>
      <p>We are exposed to realities that are quite different from ours. We interact with people in need and our actions directly impact their lives. Working with us comes with a great responsibility and requires commitment, dedication, and willingness to go on even when things look bleak.</p>
      <p>To sum up, working in Christ the King Alumni Association help people and ultimately work towards the development of the entire country.</p>
  </div>
</section>
<!--End-Section-->
<app-footer></app-footer>
