<div class="alert {{notification['class']}}" role="alert" *ngIf="notification">
  {{notification['message']}}
</div>
<form [formGroup]="enquiry_form" (ngSubmit)="submitQuery();">
  <h2>Reach to us</h2>
  <div class="mb-2">
    <input type="text" formControlName="name" class="form-control" placeholder="Enter Name" [ngClass]="{'is-invalid': (isSubmitted || f.name.touched) && f.name.invalid}" />
    <span class="invalid-feedback" *ngIf="f.name.hasError('required');">Please enter name !</span>
  </div>
  <div class="mb-2">
    <input type="text" formControlName="mobile_no" maxlength="10" class="form-control" placeholder="Enter Mobile No." [ngClass]="{'is-invalid': (isSubmitted || f.mobile_no.touched) && f.mobile_no.invalid}" />
    <span class="invalid-feedback" *ngIf="f.mobile_no.hasError('required')">Please enter Mobile No. !</span>
    <span class="invalid-feedback" *ngIf="f.mobile_no.hasError('minlength') || f.mobile_no.hasError('maxlength') || f.mobile_no.hasError('pattern')">Please enter valid Mobile No. !</span>
  </div>
  <div class="mb-2">
    <input type="email" formControlName="email" class="form-control" placeholder="Enter Email" [ngClass]="{'is-invalid': (isSubmitted || f.email.touched) && f.email.invalid}" />
    <span class="invalid-feedback" *ngIf="f.email.hasError('required')">Please enter email !</span>
    <span class="invalid-feedback" *ngIf="f.email.hasError('email')">Please enter valid email !</span>
  </div>
  <div class="mb-2">
    <input type="text" formControlName="city" class="form-control" placeholder="Enter City" [ngClass]="{'is-invalid': (isSubmitted || f.city.touched) && f.city.invalid}" />
    <span class="invalid-feedback" *ngIf="f.city.hasError('required')">Please enter city !</span>
  </div>
  <div class="mb-2">
    <textarea formControlName="message" class="form-control" rows="5" placeholder="Enquiry" [ngClass]="{'is-invalid': (isSubmitted || f.message.touched) && f.message.invalid}" ></textarea>
    <span class="invalid-feedback" *ngIf="f.message.hasError('required')">Please enter enquiry !</span>
  </div>
  <div class="d-flex justify-content-end">
    <button type="submit" class="btn btn-auto">Submit</button>
  </div>
</form>
