<app-header></app-header>
<app-breadcrumbs mainTitle="Home" pageTitle="Refund and Cancellation Policy"></app-breadcrumbs>

<section class="py-5">
  <div class="container">
    <ol>
      <li>No refund/cancellation is possible for any of the payment services except for the following circumstances</li>
      <li>Technical Error causing the User’s Bank Account/ Debit Card/UPI to be debited multiple times</li>
      <li>Technical Error causing excess amount to be debited from the User’s Bank Account/ Debit Card/ UPI in a single transaction</li>
      <li>In case of the above circumstances, Member is required to make an application for refund along with the transaction details (transaction date, amount, member details, and payment receipt details if any generated at the time of making payments) to the Association.</li>
      <li>The application in prescribed format should be sent to <strong>ctkicalumni@gmail.com</strong></li>
      <li>Any refund/cancellation requests should be made within 30 days of transaction. Any claims after 30 days will be not be accepted</li>
      <li>The application will be processed within 10 days. After verification, if the claim is found valid, the amount received in excess will be refunded.</li>
      <li>Any applicable service charges will be deducted from the refund payments.</li>
      <li>In case of any queries, please contact the association at <strong>ctkicalumni@gmail.com</strong></li>
    </ol>
  </div>
</section>

<app-footer></app-footer>
