<app-header></app-header>
<!--Breadcrumbs-->
<app-breadcrumbs [mainTitle]="mainTitle" [pageTitle]="pageTitle"></app-breadcrumbs>
<!--End-Breadcrumbs-->
<!--Section-->
<!-- <section>
  <div class="container py-5">
    <div class="shadow rounded p-2 mb-3 bg-light" *ngFor="let fame of fames">
      <div class="row">
        <div class="col-4">
          <div class="img-wrapper" style="background-image: url('{{fame.fame_image}}'); height: 200px;">
            <div class="inner-wrapper"></div>
            <img src="{{fame.fame_image}}" />
          </div>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-8">
              <span class="fs-5 fw-bold">{{fame.fame_title}}</span>
            </div>
            <div class="col-4 text-end">
              <span>{{fame.fame_date | date: 'mediumDate'}}</span>
            </div>
          </div>
          <p>{{fame.fame_description}}</p>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-4 mb-3" *ngFor="let fame of fames">
        <div class="shadow rounded p-2 bg-light">
          <div class="row">
            <div class="col-4">
              <div class="img-wrapper" style="background-image: url('{{fame.fame_image}}');">
                <div class="inner-wrapper"></div>
                <img src="{{fame.fame_image}}" />
              </div>
            </div>
            <div class="col-8">
              <h3 class="fs-5">{{fame.fame_title}}</h3>
              <div class="row">
                <div class="col-7">
                  {{fame.fame_description}}
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-3">
                  <strong>Designation</strong>
                </div>
                <div class="col-1">
                  <strong>:</strong>
                </div>
                <div class="col-7" *ngIf="formerPrincipal.former_to_date != '0000-00-00'">
                  {{formerPrincipal.former_to_date | date: 'YYYY'}}
                </div>
                <div class="col-7" *ngIf="formerPrincipal.former_to_date == '0000-00-00'">
                  Till Date
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
