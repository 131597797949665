<app-header></app-header>
<!--Breadcrumbs-->
<app-breadcrumbs [mainTitle]="mainTitle" [pageTitle]="pageTitle"></app-breadcrumbs>
<!--End-Breadcrumbs-->
<!--Section-->
<section>
  <div class="container py-5">
    <ol>
      <li class="mb-2">The main aim of the members of the association is to fulfill the objectives of the association and acquire knowledge.</li>
      <li class="mb-2">The working method and plan of the institution will be in fraternity and  wholehearted affiliation towards school throughout life.</li>
      <li class="mb-2">The purpose of the institution is to establish a place between the members and the college.</li>
      <li class="mb-2">To sponsor social and professional activities organized by the organisation and to ensure financial resources for the same.</li>
      <li class="mb-2">Conversation, financial resources, social and area development and welfare in the institution have to be continuously looked after.</li>
    </ol>
  </div>
</section>
<!--End-Section-->
<app-footer></app-footer>
